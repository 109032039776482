import dayjs from 'dayjs';

export function _GET_DATE_WITHOUT_TIMEZONE(date) {
	if (date) {
		const newDate = new Date(date);
		const data = dayjs(newDate).format('DD/MM/YYYY');
		if (data.toLowerCase() === 'invalid date') {
			return null;
		}
		return data;
	}
	return null;
}

export function _GET_DATE(date) {
	if (date) {
		const newDate = new Date(date + 'Z');
		const data = dayjs(newDate).format('DD/MM/YYYY');
		if (data.toLowerCase() === 'invalid date') {
			return null;
		}
		return data;
	}
	return null;
}

export function _GET_DATE_DDMM(date) {
	if (date) {
		const newDate = new Date(date + 'Z');
		const data = dayjs(newDate).format('DD/MM');
		if (data.toLowerCase() === 'invalid date') {
			return null;
		}
		return data;
	}
	return null;
}

export function _GET_DATE_MMYYYY(date) {
	if (date) {
		const newDate = new Date(date + 'Z');
		const data = dayjs(newDate).format('MM/YYYY');
		if (data.toLowerCase() === 'invalid date') {
			return null;
		}
		return data;
	}
	return null;
}

export function _GET_DATE_TIME(date) {
	if (date) {
		const newDate = new Date(date + 'Z');
		const data = dayjs(newDate).format('DD/MM/YYYY - HH:mm');
		if (data.toLowerCase() === 'invalid date') {
			return null;
		}
		return data;
	}
	return null;
}

export function _GET_TIME(date) {
	if (date) {
		const newDate = new Date(date + 'Z');
		const data = dayjs(newDate).format('HH:mm');
		if (data.toLowerCase() === 'invalid date') {
			return null;
		}
		return data;
	}
	return null;
}

export function _GET_DATE_2(date, noteYear = false) {
	if (!date) {
		return null;
	}
	const data_ = date.split('-');
	return noteYear
		? data_[2] + '/' + data_[1]
		: data_[2] + '/' + data_[1] + '/' + data_[0];
}

export function _GET_DATE_MM_YYYY(date) {
	if (!date || date.toLowerCase() === 'invalid date') {
		return null;
	}
	const data_ = date.split('-');
	return data_[1] + '/' + data_[0];
}

export function _GET_DATE_YYYY_MM_DD(date) {
	if (!date || date.toLowerCase() === 'invalid date') {
		return null;
	}
	if (date) {
		const data = date.split('/');
		return data[2] + '-' + data[1] + '-' + data[0];
	}
}

export function _GET_DAY(date) {
	if (!date || date.toLowerCase() === 'invalid date') {
		return null;
	}
	if (date) {
		const data = date.split('-');
		const dateNew = new Date(+data[0], +data[1] - 1, +data[2]);
		return dateNew.getDay() !== 0
			? `Thứ ${dateNew.getDay() + 1}`
			: 'Chủ Nhật';
	}
}

export function _GET_MONTH(date) {
	if (!date || date.toLowerCase() === 'invalid date') {
		return null;
	}
	if (date) {
		const data = date.split('-');
		return `Tháng ${data[1]}`;
	}
}

export function _GET_YEAR(date) {
	if (!date || date.toLowerCase() === 'invalid date') {
		return null;
	}
	if (date) {
		const data = date.split('-');
		return `${data[0]}`;
	}
}

export function _GET_DATE_TRUST(date) {
	if (!date || date.toLowerCase() === 'invalid date') {
		return null;
	}
	if (date) {
		const data = date.split('/');
		return new Date(+data[2], +data[1] - 1, +data[0]);
	}
}

export function _GET_DATE_TRUST_2(date) {
	if (!date || date.toLowerCase() === 'invalid date') {
		return null;
	}
	if (date) {
		const data = date.split('-');
		return new Date(+data[0], +data[1] - 1, +data[2]);
	}
}

export function aFromB(a, b) {
	const date1 = dayjs(a);
	const date2 = dayjs(b);
	const diff = date2.diff(date1, 'day', true);
	return Math.round(diff);
}

export function timeSince(data) {
	const now = new Date();
	const date = new Date(data + 'Z');

	const seconds = Math.floor((now - date) / 1000);

	let interval = seconds / 31536000;

	if (interval > 1) {
		return Math.floor(interval) + ' năm trước';
	}
	interval = seconds / 2592000;
	if (interval > 1) {
		return Math.floor(interval) + ' tháng trước';
	}
	interval = seconds / 86400;
	if (interval > 1) {
		return Math.floor(interval) + ' ngày trước';
	}
	interval = seconds / 3600;
	if (interval > 1) {
		return Math.floor(interval) + ' giờ trước';
	}
	interval = seconds / 60;
	if (interval > 1) {
		return Math.floor(interval) + ' phút trước';
	}
	return Math.floor(seconds) + ' giây trước';
}

export function timeSinceHour(data) {
	const now = new Date();
	const date = new Date(data + 'Z');

	const seconds = Math.floor((now - date) / 1000);

	let interval = seconds / 31536000;
	interval = seconds / 3600;
	if (interval > 48) {
		return dayjs(date).format('DD/MM/YYYY hh:mm');
	}
	if (interval > 1) {
		return Math.floor(interval) + ' giờ trước';
	}
	interval = seconds / 60;
	if (interval > 1) {
		return Math.floor(interval) + ' phút trước';
	}
	return Math.floor(seconds) + ' giây trước';
}
